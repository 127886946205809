import React from "react"
import { MdOpenInNew } from "react-icons/md"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FormLink from "../../components/formLink"

const data = [
	{
		name: "Sir Charles Gardiner Hospital Rheumatology Clinic",
		link:
			"https://www.scgh.health.wa.gov.au/Our-Services/Service-directory/Rheumatology",
	},
	{
		name: "Sir Charles Gardiner Hospital Immunology Clinic",
		link:
			"https://www.scgh.health.wa.gov.au/Our-Services/Service-directory/Immunology",
	},
	{
		name: "Royal Perth Hospital Clinical Immunology",
		link: "https://rph.health.wa.gov.au/Our-services/Clinical-Immunology",
	},
	{
		name: "Royal Perth Hospital Rheumatology Clinic",
		link: "https://www.rph.health.wa.gov.au/Our-services/Rheumatology",
	},
	{
		name: "Fiona Stanley Hospital Immunology and Rheumatology Clinics",
		link: "https://www.fionastanley.health.wa.gov.au/",
	},
]

const WAHospitals = () => (
	<Layout>
		<SEO title="Hospital Immunology/Rheumatology Clinics in WA specialising in lupus and/or autoimmune conditions" />
		<h1>Hospital Immunology/Rheumatology Clinics in WA</h1>
		<p>Clinics specialising in lupus and/or autoimmune conditions</p>
		{data.map(({ name, link }, i) => (
			<a
				key={i}
				href={link}
				target="_blank"
				rel="noopener noreferrer"
				className="block flex rounded shadow p-4 my-8 text-purple-900 bg-purple-200 focus:outline-none focus:shadow-outline hover:bg-purple-300 font-bold"
			>
				<MdOpenInNew className="mr-6 flex-grow-0 flex-shrink-0 my-auto" />
				<span className="flex-grow">{name}</span>
			</a>
		))}
	</Layout>
)

export default WAHospitals
